// This tricky is changing vuetify global style variables
@import "../styles/variables";
@import "../styles/fonts";
// TODO: --SETUP-- [Uncomment to using vuetify styles stuff like variables, functions]
// @import '~vuetify/src/styles/styles.sass';

body {
  min-width: 320px;
}

.v-btn {
  transition: all 0.4s ease !important;
}

.v-btn.black:hover, button.v-icon.white--text:hover {
  background-color: #444 !important;
}

.v-timeline-item__body .v-card {
  transition: transform .2s;
}

.v-timeline-item__body .v-card:hover {
  transform: scale(1.05);
}
